.sectionHeader {
  margin-top: 30px;
  margin-bottom: 10px;
}

.insertionFields {
  margin-top: 20px;
  padding: 30px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px dashed #bbb;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .insertionFields {
    padding: 10px;
  }
}
