body {
  margin: 0;
  padding: 0;
  font-family: rawline, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

input {
  font-family: rawline;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
button {
  font-family: rawline;
}

h2 {
  font-size: 1.3em;
}

h3 {
  font-size: 1em;
  margin: 10px 0 5px;
}

p {
  font-size: 14px;
  margin: 10px 0 5px;
}

span {
  font-size: 14px;
}

label {
  font-size: 14px;
}

a {
  text-decoration: none;
  color: #037bb4;
  font-weight: bold;
  transition: 0.2s;
}

a:hover {
  color: #97d9ef;
  transition: 0.2s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  width: 300px;
}

.english {
  display: flex;
  justify-content: center;
  align-items: center;
}

.english span {
  margin: 5px;
  font-style: italic;
}
