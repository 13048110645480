.box h1 {
  text-align: center;
}
.box {
  max-width: 700px;
  width: 100%;
  padding: 50px;
  background: #fff;
  margin: 40px auto;
  display: border-box;
}

@media only screen and (max-width: 700px) {
  .box {
    padding: 10px;
  }
}

.shadow {
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.shadow:before,
.shadow:after {
  content: '';
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  border-radius: 100px / 10px;
}

.shadow:after {
  right: 10px;
  left: auto;
  transform: skew(8deg) rotate(3deg);
}
