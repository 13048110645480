.center {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.submit {
  display: block;
  border-radius: 7px;
  color: #ffffff;
  font-size: 20px;
  background: #037bb4;
  padding: 10px 20px;
  text-decoration: none;
  transition: 0.2s;
  width: 150px;
}

.submit:hover {
  background: #3cb0fd;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
}

textarea {
  height: 100px;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #bbb;
  resize: none;
}

::placeholder {
  color: #bbb;
}

label {
  display: inline-block;
  padding: 5px;
  outline: none;
}

label input {
  height: 14px;
  width: 14px;
}

input {
  padding: 0.5rem;
  font-size: 14px;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

input:focus,
textarea:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.divNoError {
  display: flex;
  flex-direction: column;
}

.divError {
  border: 1px solid red;
  border-radius: 4px;
}

.infoText {
  color: #777;
  margin-bottom: 10px;
  margin-left: 5px;
}
